import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Routes, Route, Outlet } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import loadable from '@loadable/component'
import WOW from 'wow.js'

import ThemeProvider from '@mui/material/styles/ThemeProvider'
import CssBaseline from '@mui/material/CssBaseline'

import AuthLayout from './components/AuthLayout'
import HomeLayout from './components/HomeLayout'
import Layout from './components/Layout'
import ProtectedRoutes from './components/ProtectedRoutes'
import UnprotectedRoutes from './components/UnprotectedRoutes'

import theme from './theme'

import 'antd/dist/antd.css'
import 'react-toastify/dist/ReactToastify.css'

const AboutUsPage = loadable(() => import('./views/AboutUsPage'))
const CartPage = loadable(() => import('./views/CartPage'))
const CheckoutPage = loadable(() => import('./views/CheckoutPage'))
const CheckoutResultPage = loadable(() => import('./views/CheckoutResultPage'))
const PurchaseOrderPage = loadable(() => import('./views/PurchaseOrderPage'))
const ForgotPasswordPage = loadable(() => import('./views/ForgotPasswordPage'))
const GetHelpPage = loadable(() => import('./views/GetHelpPage'))
const LandingPage = loadable(() => import('./views/LandingPage'))
const LoginPage = loadable(() => import('./views/LoginPage'))
const MessagesPage = loadable(() => import('./views/MessagesPage'))
const MyBidPage = loadable(() => import('./views/MyBidPage'))
const MyBidDetailPage = loadable(() => import('./views/MyBidDetailPage'))
const MyProjectsPage = loadable(() => import('./views/MyProjectsPage'))
const OrdersPage = loadable(() => import('./views/OrdersPage'))
const OrderHistoryPage = loadable(() => import('./views/OrderHistoryPage'))
const OrderHistoryDetailPage = loadable(() => import('./views/OrderHistoryDetailPage'))
const ProfilePage = loadable(() => import('./views/ProfilePage'))
const ProjectPage = loadable(() => import('./views/ProjectPage'))
const QuotePage = loadable(() => import('./views/QuotePage'))
const QuotePartsPage = loadable(() => import('./views/QuotePartsPage'))
const QuotePartPage = loadable(() => import('./views/QuotePartPage'))
const QuoteShopsPage = loadable(() => import('./views/QuoteShopsPage'))
const QuoteRequestsPage = loadable(() => import('./views/QuoteRequestsPage'))
const QuoteRequestDetailsPage = loadable(() => import('./views/QuoteRequestDetailsPage'))
const QuoteReviewPage = loadable(() => import('./views/QuoteReviewPage'))
const RegisterPage = loadable(() => import('./views/RegisterPage'))
const ShopsPage = loadable(() => import('./views/ShopsPage'))
const ApplicationPage = loadable(() => import('./views/ApplicationPage'))
const ActiveOrdersPage = loadable(() => import('./views/ActiveOrdersPage'))
const ActiveOrdersDetailPage = loadable(() => import('./views/ActiveOrdersDetailPage'))
const CompletedOrdersPage = loadable(() => import('./views/CompletedOrdersPage'))
const CompletedOrdersDetailPage = loadable(() => import('./views/CompletedOrdersDetailPage'))
const MachineShopsPage = loadable(() => import('./views/MachineShopsPage'))
const EngineersPage = loadable(() => import('./views/EngineersPage'))
const ShopApplicationsPage = loadable(() => import('./views/ShopApplicationsPage'))
const ShopApplicationDetailPage = loadable(() => import('./views/ShopApplicationDetailPage'))
const ShopPaymentsPage = loadable(() => import('./views/ShopPaymentsPage'))
const POHistoryPage = loadable(() => import('./views/POHistoryPage'))
const POHistoryDetailPage = loadable(() => import('./views/POHistoryDetailPage'))
const BankConnectionPage = loadable(() => import('./views/BankConnectionPage'))
const TermsOfUseClientPage = loadable(() => import('./views/TermsOfUsePage/TermsOfUseClientPage'))
const TermsOfUseMachinistPage = loadable(() => import('./views/TermsOfUsePage/TermsOfUseMachinistPage'));
const TourPage = loadable(() => import('./views/TourPage/TourPage'))
const NotFoundPage = loadable(() => import('./views/NotFoundPage'))

const App = () => {
  const { isAuthenticated, user, layoutRide } = useSelector(state => state.auth)
  const { status } = useSelector(state => state.machineShop)

  useEffect(() => {
    new WOW().init()
  }, [])

  const getRedirectPathForAdmin = () => {
    if (user) {
      if (!(user.roles.indexOf('Admin') > -1 || user.roles.indexOf('admin') > -1)) {
        if (user.roles.indexOf('Machinist') > -1 || user.roles.indexOf('machinist') > -1) {
          if (layoutRide) {
            return '/tour'
          } else if (status === 'approved' || status === 'pending') {
            return '/quote-requests'
          }
          return '/application'
        }
        if (user.roles.indexOf('Client') > -1 || user.roles.indexOf('client') > -1) {
          return '/my-projects'
        }
      }
    }
  }

  const getRedirectPathForMachinist = () => {
    if (user) {
      if (!(user.roles.indexOf('Machinist') > -1 || user.roles.indexOf('machinist') > -1)) {
        if (user.roles.indexOf('Admin') > -1 || user.roles.indexOf('admin') > -1) {
          return '/shop-applications'
        }
        if (user.roles.indexOf('Client') > -1 || user.roles.indexOf('client') > -1) {
          return '/my-projects'
        }
      }
    }
  }

  const getRedirectPathForClient = () => {
    if (user) {
      if (!(user.roles.indexOf('Client') > -1 || user.roles.indexOf('Client') > -1)) {
        if (user.roles.indexOf('Machinist') > -1 || user.roles.indexOf('machinist') > -1) {
          if (layoutRide) {
            return '/tour'
          } else if (status === 'approved' || status === 'pending') {
            return '/quote-requests'
          }
          return '/application'
        }
        if (user.roles.indexOf('Admin') > -1 || user.roles.indexOf('admin') > -1) {
          return '/shop-applications'
        }
      }
    }
  }
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <Routes>
        <Route path="/terms-of-use-client" element={<TermsOfUseClientPage />} />
        <Route path="/terms-of-use-machinist" element={<TermsOfUseMachinistPage />} />
        {/* Both authenticated and unauthenticated users can access routes defined here */}
        <Route element={<HomeLayout />}>
          <Route path="/" element={<LandingPage />} />
          <Route path="/about-us" element={<AboutUsPage />} />
        </Route>
        <Route element={<UnprotectedRoutes isAuthenticated={isAuthenticated} />}>
          {/* Only unauthenciated users will access Routes defined here. they will be */}
          {/* redirected to LandingPage automatically if authenticated */}
          <Route element={<AuthLayout />}>
            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
          </Route>
        </Route>
        <Route element={<ProtectedRoutes isAuthenticated={isAuthenticated} isAllowed />}>
          {/* Only authenciated users will access Routes defined here. they will be */}
          {/* redirected to LoginPage automatically if unauthenticated */}
          <Route element={<Layout />}>
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/messages" element={<MessagesPage />} />
            <Route path="/help-center" element={<GetHelpPage />} />
            <Route path="/shops" element={<ShopsPage />} />
            <Route path="/about" element={<AboutUsPage />} />
          </Route>
        </Route>
        <Route
          element={
            <ProtectedRoutes
              isAuthenticated={isAuthenticated}
              isAllowed={user && (user.roles.indexOf('Admin') > -1 || user.roles.indexOf('admin') > -1)}
              redirectPath={getRedirectPathForAdmin()}
            />
          }
        >
          <Route element={<Layout />}>
            <Route path="/engineers" element={<EngineersPage />} />
            <Route path="/machine-shops" element={<MachineShopsPage />} />
            <Route path="/orders" element={<OrdersPage />} />
            <Route path="/shop-applications" element={<ShopApplicationsPage />} />
            <Route path="/shop-applications/:applicationId" element={<ShopApplicationDetailPage />} />
            <Route path="/shop-payments" element={<ShopPaymentsPage />} />
            <Route path="/po-tracking" element={<POHistoryPage />} />
            <Route path="/po-tracking/:trackingId" element={<POHistoryDetailPage />} />
          </Route>
        </Route>
        <Route
          element={
            <ProtectedRoutes
              isAuthenticated={isAuthenticated}
              isAllowed={user && (user.roles.indexOf('Machinist') > -1 || user.roles.indexOf('machinist') > -1)}
              redirectPath={getRedirectPathForMachinist()}
              applicationStatus={status === 'approved' || status === 'pending'}
            />
          }
        >
          <Route element={<Layout />}>
            <Route path="/tour" element={<TourPage />} />
            <Route path="/my-bid" element={<MyBidPage />} />
            <Route path="/my-bid/:quoteRequestId" element={<MyBidDetailPage />} />
            <Route path="/quote-requests" element={<QuoteRequestsPage />} />
            <Route path="/quote-requests/:quoteRequestId" element={<QuoteRequestDetailsPage />} />
            <Route path="/application" element={<ApplicationPage />} />
            <Route path="/active-orders" element={<ActiveOrdersPage />} />
            <Route path="/active-orders/:orderId" element={<ActiveOrdersDetailPage />} />
            <Route path="/completed-orders" element={<CompletedOrdersPage />} />
            <Route path="/completed-orders/:orderId" element={<CompletedOrdersDetailPage />} />
            <Route path="/connect" element={<BankConnectionPage />} />
          </Route>
        </Route>
        <Route
          element={
            <ProtectedRoutes
              isAuthenticated={isAuthenticated}
              isAllowed={user && (user.roles.indexOf('Client') > -1 || user.roles.indexOf('client') > -1)}
              redirectPath={getRedirectPathForClient()}
            />
          }
        >
          <Route element={<Layout />}>
            <Route path="/cart" element={<CartPage />} />
            <Route path="/checkout" element={<CheckoutPage />} />
            <Route path="/checkout/success" element={<CheckoutResultPage isSuccess={true} />} />
            <Route path="/checkout/cancel" element={<CheckoutResultPage isSuccess={false} />} />
            <Route path="/purchase-order/:cartId" element={<PurchaseOrderPage />} />
            <Route path="/order-history" element={<OrderHistoryPage />} />
            <Route path="/order-history/:orderHistoryId" element={<OrderHistoryDetailPage />} />
            <Route path="/my-projects" element={<MyProjectsPage />} />
            <Route path="/my-projects/:projectId" element={<ProjectPage />} />
            <Route path="/my-projects/:projectId/quotes/:quoteId" element={<QuotePage />} />
            <Route path="/my-projects/:projectId/quotes/:quoteId/parts" element={<QuotePartsPage />} />
            <Route path="/my-projects/:projectId/quotes/:quoteId/parts/:partId" element={<QuotePartPage />} />
            <Route path="/my-projects/:projectId/quotes/:quoteId/shops" element={<QuoteShopsPage />} />
            <Route path="/my-projects/:projectId/quotes/:quoteId/review" element={<QuoteReviewPage />} />
          </Route>
        </Route>
        <Route path="*" element={<NotFoundPage />} />
      </Routes>

      <ToastContainer theme="colored" />
    </ThemeProvider>
  )
}

export default App
